import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import DoubleImage from "../components/DoubleImage/DoubleImage"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import Menu from "../components/menus/Menu"

const ContentContainer = styled.section`
  background: ${({ theme }) => theme.light};
  padding: 2rem 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 8rem 0;
  }
`

const Rows = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 8rem 0;
  }
`

const Column = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
    padding: 0;
  }
  img {
    width: 100%;
  }
  p {
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 400;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding: 2rem;
    }
  }
`

const Title = styled.h2`
  text-align: center;
  font-size: 2rem;
  color: ${({ theme }) => theme.primary};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 3rem;
  }
`

const MenuContainer = styled.section`
  padding: 2rem 1.5rem;
  background: #eee;
`

const brewery = ({ data }) => {
  return (
    <Layout pageTitle="brewery">
      <MenuContainer>
        {/* <iframe
          src="https://business.untappd.com/embeds/iframes/30566/117794"
          frameborder="0"
          height="3000"
          width="100%"
        ></iframe> */}
        <Menu poweredListID="powered-list-9" mode="allInOnce"></Menu>
      </MenuContainer>
      <DoubleImage imageBaseUrl={data.siteMetaData.imageBaseUrl} />
      {/* <ContentContainer>
          
          <Rows>
            <Column>
              <Title>The Brewery</Title>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet
                numquam alias ex facere, excepturi laborum. Vel totam nisi
                cumque labore magnam nesciunt dolor error veritatis, corrupti
                enim quibusdam asperiores quaerat?
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid
                repellat eligendi, totam exercitationem, quia atque doloremque
                quisquam perspiciatis necessitatibus esse minus delectus.
                Dolorum provident fugit saepe nihil tenetur veritatis deleniti.
              </p>
            </Column>
            <Column>
              <img
                src={cloudinaryOptimize(
                  `https://res.cloudinary.com/gonation/image/upload/v1595267493/sites/dockside-brewing/brewery-left-1.jpg`,
                  1400
                )}
                alt="Brewery"
              />
            </Column>
          </Rows>

          <Rows>
            <Column>
              <img
                src={cloudinaryOptimize(
                  `https://res.cloudinary.com/gonation/image/upload/v1595268213/sites/dockside-brewing/brewery-right-1.jpg`,
                  1400
                )}
                alt="Brewery"
              />
            </Column>
            <Column>
              <Title>More Brewery Text</Title>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet
                numquam alias ex facere, excepturi laborum. Vel totam nisi
                cumque labore magnam nesciunt dolor error veritatis, corrupti
                enim quibusdam asperiores quaerat?
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid
                repellat eligendi, totam exercitationem, quia atque doloremque
                quisquam perspiciatis necessitatibus esse minus delectus.
                Dolorum provident fugit saepe nihil tenetur veritatis deleniti.
              </p>
            </Column>
          </Rows>
        </ContentContainer> */}
    </Layout>
  )
}

export default brewery

export const query = graphql`
  {
    siteMetaData {
      imageBaseUrl
    }
  }
`
